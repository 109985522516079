export default{
    //数字转汉字
    numberToChinese(number) {
        const chineseNumberMap = {
          0: '零',
          1: '一',
          2: '二',
          3: '三',
          4: '四',
          5: '五',
          6: '六',
          7: '七',
          8: '八',
          9: '九',
        };
      
        const chineseUnitMap = {
          1: '',
          10: '十',
          100: '百',
          1000: '千',
          10000: '万',
        };
      
        if (number < 10) {
          return chineseNumberMap[number];
        } else if (number < 20) {
          return '十' + chineseNumberMap[number % 10];
        } else if (number < 100) {
          return (
            chineseNumberMap[Math.floor(number / 10)] +
            '十' +
            (number % 10 !== 0 ? chineseNumberMap[number % 10] : '')
          );
        } else {
          let unit = 1;
          let result = '';
          while (number >= unit * 10) {
            unit *= 10;
          }
          while (unit >= 1) {
            const digit = Math.floor(number / unit);
            if (digit !== 0) {
              result += chineseNumberMap[digit] + chineseUnitMap[unit];
            }
            number %= unit;
            unit /= 10;
          }
          return result;
        }
    }
}