<template>
    <div class="executelist">
      <el-row>
        <el-col :span="5">
          <el-card class="box-card" :body-style="{ padding: '0px' }">
            <div slot="header" class="clearfix">
              <el-badge
                :value="$store.state.notice.ChargeVisit.length"
                type="warning"
              >
                <el-tag>当日收费</el-tag>
              </el-badge>
              <div style="float: right; width: 100px">
                <el-date-picker
                  class="datepicker"
                  v-model="date"
                  format="MM-dd"
                  :clearable="false"
                  :editable="false"
                  type="date"
                  size="small"
                  @change="change(date)"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="sketch_content" style="height: 700px">
              <div class="searchpatient">
                <el-input
                  clearable
                  placeholder="搜索患者"
                  prefix-icon="el-icon-search"
                  @input="searchphoneororderInput"
                  @blur="searchphoneororderblur"
                  v-model="searchpatientvalue">
                </el-input>
              </div>
              <div class="flexcolumn" v-show="showsearchpatientvalue">
                <div class="searchpatienttotal">搜索到<span class="jiachu"> {{ searchorderlist.length }} </span>条信息</div>
                <div class="searchpatientitem" 
                  v-for="(patient,index) in searchorderlist" 
                  :key="patient._id"
                  :class="chooceactive == patient._id ? 'chooceactive' : ''"
                  @click="chooceclass(index, patient)"
                >
                  <div class="flexrow">
                    <div><i :class="patient.gender === 1 ? 'el-icon-male' : 'el-icon-female'"></i></div>
                    <div>{{ patient.meetperson }}</div>
                  </div>
                  <div class="flexrow">
                    <div class="mr20">{{ patient.start }}</div>
                    <div>{{ patient.ischarge === true ? '已收' : '未收' }}</div>
                  </div>
                </div>
              </div>
              <div
                v-show="!showsearchpatientvalue"
                v-for="(items, index) in $store.state.notice.ChargeVisit"
                :key="index"
                class="meetlist"
                :class="chooceactive == items._id ? 'chooceactive' : ''"
                @click="chooceclass(index, items)"
              >
                <div class="flexrow">
                  <div><i :class="items.gender === 1 ? 'el-icon-male' : 'el-icon-female'"></i></div>
                  <div>{{ items.meetperson }}</div>
                </div>
                <div class="flexrow">
                  <div class="mr20">{{ items.products.firsttime }}-{{ items.products.endtime }}</div>
                  <div>{{ items.ischarge === true ? '已收' : '未收' }}</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <!--中间-->
        <!--个人信息-->
        <el-col :span="14" style="padding-left: 10px">
          <el-card class="userinfo" :body-style="{ padding: '0px' }">
            <div class="userinfobox">
              <div class="h3title">患者信息</div>
              <div>
                <el-button v-debounce v-if="ispaybtn" type="success" size="mini"  @click="openpaystatement(meetinfo)">收 费</el-button>
                <el-button v-debounce v-if="meetinfo.ischarge && meetinfo.statusvisit ==='finish'" type="primary" size="mini" @click="openpaystatementdetail(meetinfo)">详 情</el-button>
              </div>
            </div>
            <div class="userinfoboxwrapper">
                <el-descriptions class="margin-top" :column="4" size="medium" border :contentStyle="contentStyle" :labelStyle="labelStyle">
                    <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        名称
                    </template>
                    {{ meetinfo.meetperson }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        手机号
                    </template>
                    {{ meetinfo.mobile }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-location-outline"></i>
                        年龄
                    </template>
                    {{ meetinfo.age }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        性别
                    </template>
                    {{ meetinfo.gender === 0 ? "女" : meetinfo.gender ? "男" : "" }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
          </el-card>
          <!--个人信息-->
          <!--收款信息-->
          <el-card class="userinfo" :body-style="{ padding: '0px' }" style="margin-top: 5px;">
            <el-table
                :data="medicalrecordData"
                style="width: 100%">
                <el-table-column
                show-overflow-tooltip
                prop="diagnosis"
                label="诊断"
                width="300">
                </el-table-column>
                <el-table-column
                align="center"
                prop="doctor"
                label="医生"
                width="100">
                </el-table-column>
                <el-table-column
                align="center"
                prop="total"
                label="金额">
                </el-table-column>
                <el-table-column
                align="center"
                prop="istruetotal"
                label="实收">
                </el-table-column>
                <el-table-column
                align="center"
                prop="createdAt"
                label="时间">
                </el-table-column>
                <el-table-column
                align="center"
                label="收款状态">
                <template slot-scope="scope">
                  {{ scope.row.ischarge === true?'已收款':'未收款' }}
                </template>
                </el-table-column>
            </el-table>
          </el-card>
          <!--收款信息-->
          <!--诊疗项目-->
          <el-card class="userinfo" :body-style="{ padding: '0px' }" style="margin-top: 5px;">
            <el-table
                :data="medicalrecordconsultingData"
                style="width: 100%">
                <el-table-column
                show-overflow-tooltip
                prop="fullName"
                label="诊疗项目"
                width="200">
                </el-table-column>
                <el-table-column
                align="center"
                label="类型"
                width="80">
                <template slot-scope="scope">
                  {{ typeMap[scope.row.type] }}
                </template>
                </el-table-column>
                <el-table-column
                align="center"
                prop="Totalsellingprice"
                label="金额">
                </el-table-column>
                <el-table-column
                align="center"
                prop="istruepay"
                label="实收">
                </el-table-column>
                <el-table-column
                align="center"
                prop="defaultcount"
                label="次数or数量">
                </el-table-column>
                <el-table-column
                align="center"
                prop="selectedPackageUnit"
                label="单位">
                </el-table-column>
                <el-table-column
                align="center"
                label="收款状态">
                <template slot-scope="scope">
                  {{ returntext(scope.row.istruepay,scope.row.ischarge)}}
                </template>
                </el-table-column>
                <el-table-column
                align="center"
                label="执行">
                <template slot-scope="scope">
                  <el-button @click="submittedexecutenum(meetinfo._id,meetinfo.medicalrecordid,scope.row._id,scope.row.defaultcount,scope.row.executenum)" v-debounce v-if="scope.row.ischarge === true && scope.row.type === 4 || scope.row.type === 5" :disabled="scope.row.ischarge === true && scope.row.executenum >= scope.row.defaultcount" size="small">
                    {{scope.row.defaultcount}}/{{scope.row.executenum ||0}} 执行
                  </el-button>
                </template>
                </el-table-column>
            </el-table>
          </el-card>
          <!--诊疗项目-->
          <!--中西成药-->
          <el-card class="userinfo" :body-style="{ padding: '0px' }" style="margin-top: 5px;">
            <el-table
                :data="medicalrecordwestrecipeData"
                style="width: 100%">
                <el-table-column
                show-overflow-tooltip
                prop="fullName"
                label="中西成药"
                width="300">
                </el-table-column>
                <el-table-column
                align="center"
                prop="Totalsellingprice"
                label="金额">
                </el-table-column>
                <el-table-column
                align="center"
                prop="istruepay"
                label="实收">
                </el-table-column>
                <el-table-column
                align="center"
                prop="defaultcount"
                label="数量">
                </el-table-column>
                <el-table-column
                align="center"
                prop="selectedPackageUnit"
                label="单位">
                </el-table-column>
                <el-table-column
                align="center"
                label="收款状态">
                <template slot-scope="scope">
                  {{ returntext(scope.row.istruepay,scope.row.ischarge)}}
                </template>
                </el-table-column>
            </el-table>
          </el-card>
          <!--中西成药-->
          <!--中药处方-->
          <el-card class="userinfo" v-for="(item,index) in meetinfo.medicalrecord.zhcnrecipe" :key="index" :body-style="{ padding: '0px' }" style="margin-top: 5px;overflow: visible;">
            <div class="userinfobox borderbottom">
              <div class="h3title">中药处方{{ numberToWords(index + 1) }}</div>
              <div>
                <span>{{ returntext(item.istruepay,item.ischarge)}}</span>
              </div>
            </div>
            <div class="bottomboxborder zycf">
              <div class="child" v-for="(Zynameitem,keyid) in item.productInfos" :key="keyid">
                <div class="name-wrapper">
                  <div class="flexrow aligncenter zycf-autocomplete-wrapper medicine-autocomplete zycf-input-small-wrapper">
                    <div :id="`Zynameitem${index}${keyid}`" class="transition-element" :data-name="Zynameitem.fullName">{{ Zynameitem.fullName }}</div>
                    <span class="shortage-tips lineheight50" v-if="Zynameitem.grammage > Zynameitem.inventory">
                      <el-tooltip class="item" effect="dark" content="库存不足" placement="top">
                        <i class="el-icon-warning" style="color: #ff8100;"></i>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
                <div class="count-wrapper">
                  <div class="zycf-form-item">
                    <div class="zycf-form-item-content">
                      <span class="input-append-unit"> {{Zynameitem.grammage}}g</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottombox flexrow">
              <div class="flex-item—1">
                <div class="nested-flex nested-zycfdose">
                  <div class="zycfdose flexrow">
                    <div class="child-div">{{item.dosecount}} 剂</div>
                  </div>
                  <div class="zycfusage" style="text-align: center;">
                    <div class="bwhl-form-item is-required">
                      <div class="bwhl-form-item-content">
                        <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                          <div class="bwhl-input-wrapper count-center">
                            <div class="child-div">{{item.usages}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="zycfusage" style="text-align: center;">
                    <div class="bwhl-form-item is-required">
                      <div class="bwhl-form-item-content">
                        <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                          <div class="bwhl-input-wrapper count-center">
                            <div class="child-div">{{item.dose}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="zycfusage" style="text-align: center;">
                    <div class="bwhl-form-item is-required">
                      <div class="bwhl-form-item-content">
                        <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                          <div class="bwhl-input-wrapper count-center">
                            <div class="child-div">{{item.frequency}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="zycfusage" style="text-align: center;">
                    <div class="bwhl-form-item is-required">
                      <div class="bwhl-form-item-content">
                        <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                          <div class="bwhl-input-wrapper count-center">
                            <div class="child-div">{{item.dosagetaken}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="zycfdose flexrow">
                    <div class="child-div">加工费 ¥ {{ item.processingpreice }}</div>
                  </div>
                  <div class="flexrow">
                    <div class="child-div">备注： {{ item.notes }}</div>
                  </div>
                </div>
              </div>
              <div class="flex-item align-right jend">
                <div class="flexrow mr10">
                  <div class="mr10">{{ zhcnrecipequantity(index,item) }} 味</div>
                  <div class="mr10">单剂 {{ zhcnrecipequantitytotal(index,item) }}g</div>
                  <div class="mr10">总共 {{ zhcnrecipequantitytotal(index,item)*item.dosecount }}g</div>
                </div>
                <div>¥ {{ zhcnrecipetotalCost(index,item) }}</div>
              </div>
            </div>
          </el-card>
          <!--中药处方-->
        </el-col>
        <!--中间-->
        <el-col :span="5" style="padding-left: 10px">
          <el-card
            class="historybox"
            shadow="hover"
            :body-style="{
              padding:0,
              'background-color':'none',
              height: '100%',
              display: 'flex',
              'flex-direction': 'column',
            }"
          >
          <el-tabs type="border-card">
            <el-tab-pane label="订单记录">
              <div v-if="activities.length === 0"><el-empty description="暂无记录"></el-empty></div>
              <div v-if="activities.length > 0">
                <div class="orderrecord">
                  <el-timeline :reverse="reverse">
                    <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      :timestamp="activity.timestamp">
                      {{activity.content}}
                      <div v-if="activity.operate">
                        操作：{{activity.operate}}
                      </div>
                      <div v-if="activity.listings">
                        项目：{{activity.listings}}
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog
        :visible.sync="paystatement"
        width="55%"
        :show-close="false"
        :close-on-click-modal="false"
        class="dialogpaystatement"
        >
        <div class="flexcolumn">
          <div>
            <el-card class="box-card" style="width: 100%;">  
              <div slot="header" class="clearfix" style="text-align: center;font-size: 20px;">  
                <span>收款单</span>
              </div>
              <div class="flexcolumn">
                <div>订单号：{{ updatapaycard.out_trade_no }}</div>
                <div class="flexrow justify-space-between mt10">
                  <div>开单：{{ updatapaycard.doctor }}</div>
                  <div>收银：{{ updatapaycard.submittedpayer }}</div>
                </div>
                <div class="flexrow justify-space-between mt10">
                  <div>时间：{{ updatapaycard.updatedAt }}</div>
                  <div>会员：{{ updatapaycard.userId }}</div>
                </div>
              </div>
              <el-table
                class="mt10 paycardbox"
                height="300"
                :data="paycard"
                row-key="id"
                :row-class-name="getRowClassName"
                ref="paystatementTable"
                border
                :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                style="width: 100%">
                <el-table-column
                show-overflow-tooltip
                prop="fullName"
                label="收费项目"
                width="200">
                </el-table-column>
                <el-table-column
                align="center"
                prop="defaultcount"
                label="数量">
                </el-table-column>
                <el-table-column
                align="center"
                prop="selectedPackageUnit"
                label="单位">
                </el-table-column>
                <el-table-column
                align="center"
                prop="Totalsellingprice"
                label="金额">
                </el-table-column>
                <el-table-column
                align="center"
                prop="istruepay"
                label="实收">
                <template slot-scope="scope">
                  <el-input-number size="mini" @change="istruepayNumber" v-model="scope.row.istruepay" :precision="2" :step="1.0"></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                align="center"
                fixed="right"
                label="操作">
                  <template slot-scope="scope">
                    <el-tooltip class="itemtooltip" effect="dark" :open-delay=1000 :content="scope.row.confirm ? '撤销后可提交结算' : '删除项目不会提交结算'" placement="top-start">
                      <div
                        :class="scope.row.confirm ? 'colorred' : ''"
                        @click="changeRowColor(scope.row)"
                      >
                        {{ scope.row.confirm ? '撤销' : '删除' }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
            </el-table> 
            </el-card>
          </div>
          <div class="mt10 box-card-paytype" style="flex: 1;">
            <el-card class="box-card">  
              <div slot="header" class="clearfix flexrow justify-space-between">  
                <div>结算方式</div>
                <div style="flex:1;text-align: right;">{{ paytypename }}</div>
              </div>
              <div class="flexrow justify-space-between paytotal aligncenter">
                <div class="flexrow paybtnbox">
                  <div :class="['paybtn', changepaytypeindex === paytypeindex ? 'paybtnactive' : '']" @click="changepaytype(paytypeitem.label,paytypeitem.value,paytypeindex)" v-for="(paytypeitem,paytypeindex) in paytypeMap" :key="paytypeindex">{{ paytypeitem.label }}</div>
                </div>
                <div>
                  优惠：<el-input-number v-model="preferential" @change="preferentialNumber"  controls-position="right" :min="0"></el-input-number>
                  实收总额：<el-input-number v-model="istruetotal" controls-position="right" :min="0"></el-input-number>
                </div>
              </div> 
            </el-card>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button v-debounce size="mini" @click="cancelpaystatement">取 消</el-button>
          <el-button v-debounce size="mini" type="primary" @click="submittedpaystatement">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="paystatementdetail"
        width="55%"
        :show-close="false"
        :close-on-click-modal="false"
        class="dialogpaystatement"
        >
        <div class="flexcolumn">
          <div>
            <el-card class="box-card" style="width: 100%;">  
              <div slot="header" class="clearfix" style="text-align: center;font-size: 20px;">  
                <span>详情</span>
              </div>
              <div class="flexcolumn">
                <div>订单号：{{ updatapaycard.out_trade_no }}</div>
                <div class="flexrow justify-space-between mt10">
                  <div>开单：{{ updatapaycard.doctor }}</div>
                  <div>收银：{{ updatapaycard.submittedpayer }}</div>
                </div>
                <div class="flexrow justify-space-between mt10">
                  <div>时间：{{ updatapaycard.updatedAt }}</div>
                  <div>会员：{{ updatapaycard.userId }}</div>
                </div>
              </div>
              <el-table
                class="mt10 paycardbox"
                height="300"
                :data="paycarddetail"
                row-key="id"
                :row-class-name="getRowClassName"
                ref="paystatementTable"
                border
                :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                style="width: 100%">
                <el-table-column
                show-overflow-tooltip
                prop="fullName"
                label="收费项目"
                width="200">
                </el-table-column>
                <el-table-column
                align="center"
                prop="defaultcount"
                label="数量">
                </el-table-column>
                <el-table-column
                align="center"
                prop="selectedPackageUnit"
                label="单位">
                </el-table-column>
                <el-table-column
                align="center"
                prop="Totalsellingprice"
                label="金额">
                </el-table-column>
                <el-table-column
                align="center"
                prop="istruepay"
                label="实收">
                </el-table-column>
                <el-table-column
                align="center"
                fixed="right"
                label="操作">
                  <template slot-scope="scope">
                    <el-tooltip class="itemtooltip" effect="dark" :open-delay=1000 content="选择退款的项目提交后将返回库存" placement="top-start">
                      <div
                        :class="scope.row.ischarge ? 'colorred' : ''"
                        @click="changerebackpay(scope.row)"
                      >
                        {{ scope.row.ischarge ? '退款' : '撤销' }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
            </el-table> 
            </el-card>
          </div>
          <div class="mt10 box-card-paytype" style="flex: 1;">
            <el-card class="box-card">  
              <div slot="header" class="clearfix flexrow justify-space-between">  
                <div>退款方式</div>
                <div style="flex:1;text-align: right;">{{ paytypename }}</div>
              </div>
              <div class="flexrow justify-space-between paytotal aligncenter">
                <div class="flexrow paybtnbox">
                  <div :class="['paybtn', changepaytypeindex === paytypeindex ? 'paybtnactive' : '']" @click="changepaytype(paytypeitem.label,paytypeitem.value,paytypeindex)" v-for="(paytypeitem,paytypeindex) in paytypeMap" :key="paytypeindex">{{ paytypeitem.label }}</div>
                </div>
                <div>
                  退款总额：<el-input-number v-model="istruetotal" controls-position="right" :min="0"></el-input-number>
                </div>
              </div> 
            </el-card>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button v-debounce size="mini" @click="cancelpaystatementdetail">取 消</el-button>
          <el-button v-debounce size="mini" type="primary" @click="refundpaystatementdetail">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import store from "@/store";
  import { BButton } from "bootstrap-vue";
  import Moment from "moment";
  import numberToChinese from "./numberToChinese.js"
  export default {
    name: "index",
    components: {
      BButton,
    },
    data() {
      return {
        // 版本号
        reverse:false,
        activities: [],
        paystatement:false,
        paystatementdetail:false,
        paycard:[],
        paycarddetail:[],
        updatapaycard:{},
        selectedRows: [],
        preferential:0,
        medicalrecordData: [],
        medicalrecordconsultingData: [],
        medicalrecordwestrecipeData: [],
        typeMap: {  
          1: '中西成药',  
          2: '中药',  
          3: '商品',  
          4: '治疗',  
          5: '理疗',  
          6: '物资'  
        }, 
        paytype:1,
        paytypename:"微信支付",
        changepaytypeindex:0,
        paytypeMap: [
          {
            value:1,
            label:"微信支付"
          },
          {
            value:2,
            label:"支付宝支付"
          },
          {
            value:3,
            label:"刷卡支付"
          },
          {
            value:4,
            label:"现金支付"
          },
        ], 
        istruetotal:0,
        version: "3.3.0",
        bodyStyle: { padding: '40px'},
        dynamicValidateForm: {
          domains: [
            {
              value: null,
            },
          ],
        },
        contentStyle: {  
            minWidth: '100px', // 固定宽度为300像素  
            wordBreak: 'break-all' // 允许在单词内换行  
        },  
        labelStyle: {  
        },  
        tableKey: 1,
        showPopuppatientdescribe:false,
        showPopuphistoryofpresent:false,
        showPopuppasthistory:false,
        showPopupallergyhistory:false,
        showPopupauscultationandpalpation:false,
        showPopupdiagnosis:false,
        showPopupdialectical:false,
        showPopupexhort:false,
        showPopupzhengliaoxiangmu:false,
        showPopupzhongxichengyao:false,
        showPopupusageselect:[],
        showPopupufreq:false,
        showPopupinspect:false,
        showPopupzhcnrecipe:[],
        showPopupzycfusageselect:[],
        showPopupzycfdoseselect:[],
        showPopupuzycffrequency:[],
        showPopupudosagetaken:[],
        showPopupupdatazhcnrecipe:[[]],
        displayNameItem:[[]],
        serachdisplayName: "",
        zycfdialogVisible:false,
        zycfdemo:'',
        activeNames: ['1'],
        searchpatientvalue:"",
        timer: null,//防抖参数
        showsearchpatientvalue:false,
        searchwesternmedicine:"",
        searchorderlist:[],
        categorizedData:{},
        showPopup:false,
        delbtn:false,
        showzhengliaoxiangmu: null,
        showDiv: null,
        searchchconsulting: "",
        selectedPackageUnit: '',
        searchZynameitem: '',
        multipleSelection:[],
        isMultiple:false,
        detailtitle: "",
        detaildata: [],
        loading: false,
        westdrugnum: 0,
        zhcndrugnum: 0,
        consultnum: 0,
        drugnummax: null,
        isdisabled: false,
        consultingdialog: false,
        dialogTableVisible: false,
        shortcutzhcndrugdialog: false,
        zhcndrugdialog: false,
        userData: JSON.parse(localStorage.getItem("userData")),
        date: Moment(new Date()).format("YYYY-MM-DD"),
        chooceactive: -1,
        searchdrug: "",
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页数据量
        total: 0, // 总数据量
        zhcncurrentPage: 1, // 当前页码
        zhcnpageSize: 10, // 每页数据量
        zhcntotal: 0, // 总数据量
        consultcurrentPage: 1, // 当前页码
        consultpageSize: 10, // 每页数据量
        consulttotal: 0, // 总数据量
        tableData: [],
        tableData2: [],
        zhcndrugData: [],
        consultData: [],
        adddrugs: [],
        meetinfo: {
          _id: "",
          openid: "",
          meetperson: "",
          age:null,
          mobile: "",
          idcard: "",
          gender: "",
          doctor: "",
          date: "",
          status: "",
          statusvisit: "",
          ischarge:false,
          switch:true,
          blswitch:true,
          medicalrecord: {
            visituserid:"",
            visitusername:"",
            visittime:"",
            doctor:"",
            patientdescribe:"",//主诉
            historyofpresent:"",//现病史
            pasthistory:"",//既往史
            allergyhistory:"",//过敏史
            auscultationandpalpation:"",//望闻切诊
            diagnosis:"",//诊断
            inspect:"",//检查
            dialectical:"",//辩证
            exhort: "",//医嘱
            history: "",
            symptom: "",
            westrecipe: [],
            zhcnrecipe: [],
            prescribetitle: "",
            prescribenum: 1,
            consulting: [],
            consultingtotalCost:null,
            total: 0,
          },
        },
        content: {
          text1: "修改病历后，原来保存的将会覆盖，同时处方的药品数量也会还原",
          text2: "一般用在当患者签到后，又没有会诊，订单将会取消，患者需重新预约",
          text3: "保存后病历与处方将会保存",
          text4: "会诊完成，可以接待下个患者",
        },
        orderlist: [],
        options: [],
        selectiondrugs: [],
        selectionzhcndrug: [],
        selectionconsulting: [],
        savedrugs: [],
        savezhcndrugs: [],
        saveconsulting: [],
        saveconsultingdata: [],
        arrhistory: [],
        historylist: [],
        arrsymptom: [],
        symptomlist: [],
        arrdiagnosis: [],
        diagnosislist: [],
        arradvice: [],
        advicelist: [],
        optionshistory: [],
        optionssymptom: [],
        optionsdiagnosis: [],
        optionsadvice: [],
        arrshortcutzhcndrug: [],
        optionsshortcutzhcndrug: [],
        shortcutzhcndruglist: [],
        outerVisible: false,
        innerVisible: false,
        visithistory:[],
        visithistorydata:[],
        historydetail:{},
        listQuery:{},
        xxx:[],
        xxxx:[],
        patients: [
          { name: 'Patient 1', medicalRecord: '' },
          { name: 'Patient 2', medicalRecord: '' },
          // ...
        ],
        gridDataceshi1:[],
        gridDataceshi2:[],
        gridDataceshi3:[],
        inputValue:"",
        visible: false,
        showDeleteIcon: [],
        showDeleteIcon1: [],
        zhcnrecipearrayindex:null,//父索引
        zhcnrecipearraykeyid:null,//子索引
        displayNameFocusCount:0,
        categoriesoptions: [],
        childrenCategory: [],
        medicineTemplate: [],
        selectedIndex:0,
        searchkeyIndex:0,
        childrenselectedIndex:0,
        isSelecteddata:{
          name:"",
          category:[{name:""}],
          Creator:{},
          ingredients:[],
          effect:"",
          source:"",
          attending:"",
          dosecount:"",
          usages:"",
          dose:"",
          frequency:"",
          usages:"",
          dosagetaken:"",
        },
      zhcnrecipeindex:0,
      searchmedicineTemplateswitch:false,
      searchmedicineTemplatetext:"",
      searchmedicineTemplatedata:[],
      medicineTemplatefocus:false,
      sexoptions:[{
          value: 1,
          label: '男'
        }, {
          value: 0,
          label: '女'
        }, 
      ]
      }
    },
    watch:{ 
    },
    methods: {
      submittedexecutenum(orderid,_id,child_id,defaultcount,executenum){
        const data = {
          orderid,_id,child_id,defaultcount,executenum
        }
        if(defaultcount >  executenum){
          this.$confirm("将在原有的基础上减去一次使用次数", "确认信息", {
            distinguishCancelAndClose: true,
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            confirmButtonType: "Primary",
            cancelButtonType: "Primary"
          }).then(() => {
              this.$api.visit.submittedexecutenum(data).then((res) => {
                if (res.data.code === 200) {
                  this.chooceclass(this.chooceactive, res.data.data)

                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                }else{
                  this.$message({
                    type: "info",
                    message: "该项目已全部执行完毕",
                  });
                }
              });
          }).catch((action) => {
            this.$message({
              type: "info",
              message: action === "cancel" ? "放弃" : "关闭",
            });
          })
        }else{
          this.$message({
            type: "info",
            message: "该项目执行次数已完成",
          });
        }
      },
      returntext(num,bln){
        if(num === 0 && bln === false){
          return "未付款"
        }
        if(num > 0 && bln === true){
          return "已付款"
        }
        if(num > 0 && bln === false){
          return "已退款"
        }
      },
      istruepayNumber(){
        let itemistruepay = this.paycard.filter(item => item.confirm === false).reduce((sum, item) => sum + item.istruepay, 0);
        this.istruetotal = (itemistruepay - this.preferential).toFixed(2)
        this.updatapaycard.istruetotal = this.istruetotal
      },
      preferentialNumber(){
        let checkPlan = '' + this.preferential
        checkPlan = checkPlan
          .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace(/^\./g, '') // 保证第一个为数字而不是.
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
        if (checkPlan.indexOf('.') < 0 && checkPlan !== '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          checkPlan = parseFloat(checkPlan) + ''
        } else if (checkPlan.indexOf('.') >= 0) {
          checkPlan = checkPlan
            .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
        }
        this.preferential = checkPlan
        let itemistruepay = this.paycard.filter(item => item.confirm === false).reduce((sum, item) => sum + item.istruepay, 0);
        this.istruetotal = (itemistruepay - this.preferential).toFixed(2)
        this.updatapaycard.istruetotal = this.istruetotal
      },
      preferentialdetailNumber(){
        let checkPlan = '' + this.preferential
        checkPlan = checkPlan
          .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace(/^\./g, '') // 保证第一个为数字而不是.
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
        if (checkPlan.indexOf('.') < 0 && checkPlan !== '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          checkPlan = parseFloat(checkPlan) + ''
        } else if (checkPlan.indexOf('.') >= 0) {
          checkPlan = checkPlan
            .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
        }
        this.preferential = checkPlan
        let itemistruepay = this.paycarddetail.filter(item => item.ischarge === true).reduce((sum, item) => sum + item.istruepay, 0);
        this.istruetotal = (itemistruepay - this.preferential).toFixed(2)
        this.updatapaycard.istruetotal = this.istruetotal
      },
      changepaytype(label,value,index){
        this.paytype = value
        this.paytypename = label
        this.changepaytypeindex = index
        this.updatapaycard.ispaytype = this.paytype
      },
      refundpaystatementdetail(){
        if(this.updatapaycard.medicalrecord.length === 0 ){
          return this.$alert("没有需要退货的物品", '提示', {
            confirmButtonText: '确定',
          });
        }
        if(this.updatapaycard.medicalrecord.length > 0 ){
          let lengthnum = this.updatapaycard.medicalrecord.length
          this.$confirm(`有${lengthnum}个需要退货的项目`, "确认信息", {
            distinguishCancelAndClose: true,
            confirmButtonText: "提交",
            cancelButtonText: "关闭",
            confirmButtonType: "Primary",
            cancelButtonType: "Primary"
          }).then(() => {
              this.$api.meetorder.refundpaystatement(this.updatapaycard).then((res) => {
                if (res.data.code === 200) {
                  this.paystatementdetail = false
                  this.chooceclass(this.chooceactive, res.data.data)
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  this.paycarddetail = []
                }else{
                  this.$alert(res.data.data, '退货失败', {
                    confirmButtonText: '确定',
                  });
                }
              });
          }).catch((action) => {
            this.$message({
                type: "info",
                message: action === "cancel" ? "放弃" : "关闭",
              });
          })
        }
      },
      submittedpaystatement(){
        if(this.updatapaycard.medicalrecord.length === 0 ){
          return this.$alert("没有需要结算的物品", '提示', {
            confirmButtonText: '确定',
          });
        }
        if(this.updatapaycard.medicalrecord.length > 0 ){
          let lengthnum = this.updatapaycard.medicalrecord.length
          this.$confirm(`有${lengthnum}个需要结算的项目`, "确认信息", {
            distinguishCancelAndClose: true,
            confirmButtonText: "保存",
            cancelButtonText: "关闭",
            confirmButtonType: "Primary",
            cancelButtonType: "Primary"
          }).then(() => {
              this.$api.meetorder.submittedpaystatement(this.updatapaycard).then((res) => {
                if (res.data.code === 200) {
                  this.paystatement = false
                  this.chooceclass(this.chooceactive, res.data.data)
                  this.$message({
                    type: "success",
                    message: res.data.msg,
                  });
                  this.paystatement = false
                  this.paycard = []
                }else{
                  this.$alert(res.data.data, '库存不足', {
                    confirmButtonText: '确定',
                  });
                }
              });
          }).catch((action) => {
            this.$message({
                type: "info",
                message: action === "cancel" ? "放弃" : "关闭",
              });
          })
        }
      },
      getRowClassName({row, rowIndex}) {
        if (row.confirm === true) {
          return 'warning-row';
        }
        return '';
      },
      changeRowColor(row) {
        row.confirm = !row.confirm;
        this.updatapaycard.medicalrecord = this.paycard.filter(item => item.confirm === false); 
        let itemistruepay = this.paycard.filter(item => item.confirm === false).reduce((sum, item) => sum + item.istruepay, 0);
        this.istruetotal = (itemistruepay - this.preferential).toFixed(2)
        this.updatapaycard.istruetotal = this.istruetotal
      },
      changerebackpay(row) {
        console.log(row)
        row.ischarge = !row.ischarge;
        this.updatapaycard.medicalrecord = this.paycarddetail.filter(item => item.ischarge === false); 
        console.log(this.updatapaycard.medicalrecord.length)
        let itemistruepay = this.paycarddetail.filter(item => item.ischarge === false).reduce((sum, item) => sum + item.istruepay, 0);
        this.istruetotal = (itemistruepay - this.preferential).toFixed(2)
        this.updatapaycard.istruetotal = this.istruetotal
      },
      convertStringToNumber(str) {  
        const num = parseFloat(str);  
        const decimalPlaces = num.toString().split('.')[1];  
        if (decimalPlaces && decimalPlaces.length > 2) {  
          return Math.round(num * 100) / 100;  
        } else {  
          return num;  
        }  
      },
      cancelpaystatement(){
        this.paystatement = false
        this.paycard = []
      },
      cancelpaystatementdetail(){
        this.paystatementdetail = false
        this.paycarddetail = []
      },
      openpaystatementdetail(){
        this.paystatementdetail = true
        let updata = []
        if(this.meetinfo.medicalrecord.consulting.length > 0){
          let newArrayconsulting = this.meetinfo.medicalrecord.consulting.filter(item => item.ischarge === true).map(item => {  
            return {  
              _id: item._id,
              goodid: item.goodid,
              fullName: item.fullName,
              updatatype:1,
              type:item.type,
              Totalsellingprice: item.Totalsellingprice,
              defaultcount: item.defaultcount,
              istruepay: item.istruepay,
              selectedPackageUnit:item.selectedPackageUnit, 
              ischarge:item.ischarge
            };  
          });
          updata.push(...newArrayconsulting)
        }
        if(this.meetinfo.medicalrecord.westrecipe.length > 0){
          let newArraywestrecipe = this.meetinfo.medicalrecord.westrecipe.filter(item => item.ischarge === true).map(item => {  
            return {  
              _id: item._id,
              goodid: item.goodid,
              fullName: item.fullName,
              updatatype:2,
              type:item.type,
              Totalsellingprice: item.Totalsellingprice,
              defaultcount: item.defaultcount,  
              istruepay: item.istruepay,
              selectedPackageUnit:item.selectedPackageUnit, 
              ischarge:item.ischarge
            };  
          });
          updata.push(...newArraywestrecipe)
        }
        if(this.meetinfo.medicalrecord.zhcnrecipe.length > 0){
          let newArrayzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.filter(item => item.ischarge === true).map((item, index) => {  
            return {  
              _id: item._id,
              fullName: `中药处方${index + 1}`,
              updatatype:3,
              Totalsellingprice: item.totalPrice,
              defaultcount: item.dosecount,  
              istruepay: item.istruepay,
              selectedPackageUnit: "剂", 
              processingpreice: item.processingpreice,
              productInfos: item.productInfos,
              ischarge:item.ischarge
            };  
          });
          updata.push(...newArrayzhcnrecipe)
        }
        this.paycarddetail.push(...updata)
        this.updatapaycard._id = this.meetinfo._id
        this.updatapaycard.out_trade_no = this.meetinfo.out_trade_no
        this.updatapaycard.doctor = this.meetinfo.doctor
        this.updatapaycard.submittedpayer = this.userData.fullName
        this.updatapaycard.submittedpayid = this.userData._id
        this.updatapaycard.userId = this.meetinfo.userId
        this.updatapaycard.ispaytype = this.paytype
        this.updatapaycard.updatedAt = Moment(this.meetinfo.updatedAt).format("YYYY-MM-DD HH:mm:ss")
        this.updatapaycard.medicalrecord = this.paycarddetail.filter(item => item.ischarge === false);
        this.updatapaycard.istruetotal = this.paycarddetail.reduce((sum, item) => sum + item.istruepay, 0);
        this.updatapaycard.preferential = this.preferential
        this.updatapaycard.medicalrecordid = this.meetinfo.medicalrecordid
        this.istruetotal = this.paycarddetail.filter(item => item.ischarge === false).reduce((sum, item) => sum + item.istruepay, 0);
      },
      openpaystatement(){
        this.paystatement = true
        let updata = []
        if(this.meetinfo.medicalrecord.consulting.length > 0){
          let newArrayconsulting = this.meetinfo.medicalrecord.consulting.filter(item => item.ischarge === false).map(item => {  
            return {  
              _id: item._id,
              goodid: item.goodid,
              fullName: item.fullName,
              updatatype:1,
              type:item.type,
              Totalsellingprice: item.Totalsellingprice,
              defaultcount: item.defaultcount,
              istruepay: this.convertStringToNumber(item.Totalsellingprice),
              selectedPackageUnit:item.selectedPackageUnit,
              executenum: item.executenum || 0,
              confirm:false
            };  
          });
          updata.push(...newArrayconsulting)
        }
        if(this.meetinfo.medicalrecord.westrecipe.length > 0){
          let newArraywestrecipe = this.meetinfo.medicalrecord.westrecipe.filter(item => item.ischarge === false).map(item => {  
            return {  
              _id: item._id,
              goodid: item.goodid,
              fullName: item.fullName,
              updatatype:2,
              type:item.type,
              Totalsellingprice: item.Totalsellingprice,
              defaultcount: item.defaultcount,  
              istruepay: this.convertStringToNumber(item.Totalsellingprice),
              selectedPackageUnit:item.selectedPackageUnit, 
              confirm:false
            };  
          });
          updata.push(...newArraywestrecipe)
        }
        if(this.meetinfo.medicalrecord.zhcnrecipe.length > 0){
          let newArrayzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.filter(item => item.ischarge === false).map((item, index) => {  
            return {  
              _id: item._id,
              fullName: `中药处方${index + 1}`,
              updatatype:3,
              Totalsellingprice: item.totalPrice,
              defaultcount: item.dosecount,  
              istruepay: this.convertStringToNumber(item.totalPrice),
              selectedPackageUnit: "剂", 
              processingpreice: item.processingpreice,
              productInfos: item.productInfos,
              confirm:false
            };  
          });
          updata.push(...newArrayzhcnrecipe)
        }
        this.paycard.push(...updata)
        this.updatapaycard._id = this.meetinfo._id
        this.updatapaycard.out_trade_no = this.meetinfo.out_trade_no
        this.updatapaycard.doctor = this.meetinfo.doctor
        this.updatapaycard.submittedpayer = this.userData.fullName
        this.updatapaycard.submittedpayid = this.userData._id
        this.updatapaycard.userId = this.meetinfo.userId
        this.updatapaycard.ispaytype = this.paytype
        this.updatapaycard.updatedAt = Moment(this.meetinfo.updatedAt).format("YYYY-MM-DD HH:mm:ss")
        this.updatapaycard.medicalrecord = this.paycard.filter(item => item.confirm === false);
        this.updatapaycard.istruetotal = this.paycard.reduce((sum, item) => sum + item.istruepay, 0);
        this.updatapaycard.preferential = this.preferential
        this.updatapaycard.medicalrecordid = this.meetinfo.medicalrecordid
        this.istruetotal = this.paycard.reduce((sum, item) => sum + item.istruepay, 0);
      },
      zhcnrecipetotalCost(index, item) {
      let totalPrice = 0.00;
      let total = 0.00;
      const dosecount = this.meetinfo.medicalrecord.zhcnrecipe[index]?.dosecount;
      const processingpreice = this.meetinfo.medicalrecord.zhcnrecipe[index]?.processingpreice;
      const numprocessingpreice = parseInt(processingpreice);
      const productInfos = this.meetinfo.medicalrecord.zhcnrecipe[index]?.productInfos;
      if (!productInfos || productInfos.length === 0 || dosecount < 0) {
        return '0.00';
      }
      const filteredProductInfos = productInfos.filter(i => i.grammage > 0);
      filteredProductInfos.forEach(i => {
        if (i.grammage > 0 && numprocessingpreice > 0) {
          total += i.grammage * i.sellprice * dosecount;
          totalPrice = total + numprocessingpreice
        }else if(i.grammage > 0){
          total += i.grammage * i.sellprice * dosecount;
          totalPrice = total
        }else{
          totalPrice = 0.00
        }
      });
      item.totalPrice = totalPrice.toFixed(2);
      return totalPrice.toFixed(2)
      },
      zhcnrecipequantity(index, item) {
        const dosecount = this.meetinfo.medicalrecord.zhcnrecipe[index]?.dosecount;
        const productInfos = this.meetinfo.medicalrecord.zhcnrecipe[index]?.productInfos;
        const filteredProductInfos = productInfos.filter(i => i.grammage > 0);
        return filteredProductInfos.length
      },
      zhcnrecipequantitytotal(index, item) {
        const productInfos = this.meetinfo.medicalrecord.zhcnrecipe[index]?.productInfos;
        const filteredProductInfos = productInfos.filter(i => i.grammage > 0);
        const total = filteredProductInfos.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.grammage), 0);
        const totalPrice = this.meetinfo.medicalrecord.zhcnrecipe.map(item => Number(item.totalPrice)).reduce((sum, price) => sum + price, 0); 
        this.meetinfo.medicalrecord.zhcnrecipetotalPrice = totalPrice.toFixed(2)
        this.agreeChange(this.meetinfo._id, totalPrice, 'medicalrecord.zhcnrecipetotalPrice');
        return total;
      },
      formatDate(dateString) {  
        var dateParts = dateString.split(' ');  
        var formattedDate = dateParts[0].split('-'); 
        var formattedDate1 = formattedDate[1] + '-' + formattedDate[2]; 
        return formattedDate1;  
      },  
      //防抖的函数
      debounce(func, delay) {
        clearTimeout(this.timer);
        this.timer = setTimeout(func, delay);
      },
      //防抖
      searchphoneororderInput() {
        this.debounce(this.searchphoneororder, 500);
      },
      searchphoneororderblur() {
        if(this.searchpatientvalue === '')
        this.showsearchpatientvalue = false
      },
      //搜索患者
      searchphoneororder(){
        if(!this.searchpatientvalue){
          return this.showsearchpatientvalue = false
        }
        this.$api.meetorder.searchphoneororder(this.searchpatientvalue).then((res) => {
          this.showsearchpatientvalue = true
          if(res.data.code === 200){
            this.searchorderlist = res.data.data
          }else{
            this.searchorderlist = []
          }
        });
      },
      collapsehistoryChange(val) {
        console.log(val);
      },
      zycfdemoClick(tab, event) {
        console.log(tab.name);
        this.zycfdemo = tab.name
        this.getchildrenCategory()
      },
      numberToWords(num){
        return numberToChinese.numberToChinese(num)
      },
      agreeChange(id,value,key) {
        store.commit('notice/updateMeetperson', { id, value, key });
      },
      chooceclass(index, data) {
        this.chooceactive = data._id;
        this.meetinfo._id = data._id;
        this.meetinfo.openid = data.openid;
        this.meetinfo.userId = data.userId;
        this.meetinfo.meetperson = data.meetperson;
        this.meetinfo.mobile = data.mobile;
        this.meetinfo.idcard = data.idcard;
        this.meetinfo.age = data.age;
        this.meetinfo.gender = data.gender;
        this.meetinfo.doctor = data.doctor;
        this.meetinfo.status = data.status;
        this.meetinfo.signin = data.signin;
        this.meetinfo.out_trade_no = data.out_trade_no;
        this.meetinfo.statusvisit = data.statusvisit;
        this.meetinfo.blswitch = data.blswitch;
        this.meetinfo.ischarge = data.ischarge;
        this.meetinfo.date = Moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss");
        this.meetinfo.medicalrecord.visituserid = data.meetpersonid;
        this.getorderrecordbyid(data._id)
        this.meetinfo.medicalrecordid = data.medicalrecord ? data.medicalrecord._id : "";
        this.meetinfo.medicalrecord.visittime = Moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss");
        this.meetinfo.medicalrecord.doctor = data.doctor;
        this.meetinfo.medicalrecord.visitusername = data.meetperson;
        this.meetinfo.medicalrecord.patientdescribe = data.medicalrecord ? data.medicalrecord.patientdescribe : "";
        this.meetinfo.medicalrecord.historyofpresent = data.medicalrecord ? data.medicalrecord.historyofpresent : "";
        this.meetinfo.medicalrecord.pasthistory = data.medicalrecord ? data.medicalrecord.pasthistory : "";
        this.meetinfo.medicalrecord.allergyhistory = data.medicalrecord ? data.medicalrecord.allergyhistory : "";
        this.meetinfo.medicalrecord.auscultationandpalpation = data.medicalrecord ? data.medicalrecord.auscultationandpalpation : "";
        this.meetinfo.medicalrecord.diagnosis = data.medicalrecord ? data.medicalrecord.diagnosis : "";
        this.meetinfo.medicalrecord.dialectical = data.medicalrecord ? data.medicalrecord.dialectical : "";
        this.meetinfo.medicalrecord.exhort = data.medicalrecord ? data.medicalrecord.exhort : "";
        this.meetinfo.medicalrecord.inspect = data.medicalrecord ? data.medicalrecord.inspect : "";
        this.meetinfo.medicalrecord.history = data.medicalrecord ? data.medicalrecord.history : "";
        this.meetinfo.medicalrecord.symptom = data.medicalrecord ? data.medicalrecord.symptom : "";
        this.meetinfo.medicalrecord.westrecipe = data.medicalrecord && data.medicalrecord.westrecipe !== undefined ? data.medicalrecord.westrecipe : [];
        this.meetinfo.medicalrecord.zhcnrecipe = data.medicalrecord && data.medicalrecord.zhcnrecipe !== undefined ? data.medicalrecord.zhcnrecipe : [];
        this.meetinfo.medicalrecord.consulting = data.medicalrecord && data.medicalrecord.consulting !== undefined ? data.medicalrecord.consulting : [];
        this.meetinfo.medicalrecord.consultingtotalCost = data.medicalrecord && data.medicalrecord.consultingtotalCost !== undefined ? data.medicalrecord.consultingtotalCost : 0;
        this.meetinfo.medicalrecord.prescribetitle = data.medicalrecord ? data.medicalrecord.prescribetitle : "";
        this.meetinfo.medicalrecord.prescribenum = data.medicalrecord ? data.medicalrecord.prescribenum : 0;
        this.meetinfo.medicalrecord.total = data.medicalrecord ? data.medicalrecord.total : 0;
        let addmedicalrecordData = [{
          diagnosis:this.meetinfo.medicalrecord.diagnosis,
          doctor:this.meetinfo.medicalrecord.doctor,
          total:(data.medicalrecord.consultingtotalCost || 0) + (data.medicalrecord.westrecipetotalCost || 0) + (data.medicalrecord.zhcnrecipetotalPrice || 0),
          istruetotal:data.istruetotal,
          createdAt:Moment(data.createdAt).format("MM-DD"),
          ischarge:data.ischarge
        }]
        this.medicalrecordData = addmedicalrecordData
        this.medicalrecordconsultingData = data.medicalrecord && data.medicalrecord.consulting !== undefined ? data.medicalrecord.consulting : [];
        this.medicalrecordwestrecipeData = data.medicalrecord && data.medicalrecord.westrecipe !== undefined ? data.medicalrecord.westrecipe : [];
      },
      change(date) {
        this.date = Moment(date).format("YYYY-MM-DD");
        this.loadItems();
      },
      loadItems() {
        const data = {
          date: this.date,
        };
        store.dispatch("notice/fetchchargeVisit", data);
      },
      //保存病例
      saveprescription() {
        if(!this.meetinfo.medicalrecord.patientdescribe){
          return this.$message({
            type: "danger",
            message: "主诉不能为空",
          });
        }
        if(!this.meetinfo.medicalrecord.diagnosis){
          return this.$message({
            type: "danger",
            message: "诊断不能为空",
          });
        }
        let allDefaultconsulting = this.meetinfo.medicalrecord.consulting.every(item => item.defaultcount > 0); 
        let allDefaultwestrecipe = this.meetinfo.medicalrecord.westrecipe.every(item => item.defaultcount > 0); 
        let allDefaultzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.dosecount > 0);
        let zhcnrecipeproductInfos = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.productInfos.every(info => info.grammage > 0));   
        if(!allDefaultconsulting || !allDefaultwestrecipe || !allDefaultzhcnrecipe || !zhcnrecipeproductInfos){
          return this.$message({
            type: "danger",
            message: "总量不能为空",
          });
        }
        this.$confirm("点击完诊将会保存病例以及处方", "确认信息", {
          distinguishCancelAndClose: true,
          confirmButtonText: "保存",
          cancelButtonText: "关闭",
        })
          .then(() => {
            const data = {
              _id: this.meetinfo._id,
              openid: this.meetinfo.openid,
              gender: this.meetinfo.gender,
              age: this.meetinfo.age,
              medicalrecord: this.meetinfo.medicalrecord,
            };
            this.$api.visit.saveprescription(data).then((res) => {
              if (res.data.code === 200) {
                this.meetinfo.statusvisit = res.data.data.statusvisit;
                this.meetinfo.blswitch = res.data.data.blswitch
                this.agreeChange(this.meetinfo._id, true, 'blswitch');
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
              }else{
                this.$alert(res.data.msg, '发生错误', {
                  confirmButtonText: '确定',
                });
              }
            });
          })
          .catch((action) => {
            this.isdisabled = true;
            this.$message({
              type: "info",
              message: action === "cancel" ? "放弃保存" : "关闭弹窗",
            });
          });
      },
      getorderrecordbyid(id){
        this.$api.meetorder.getorderrecordbyid(id).then((res) => {
          if(res.data.data){
            this.activities = res.data.data.record
          }else{
            this.activities = []
          }
        }).catch(error => {
          console.log(error);
        })
      },
      getidmeetorder(id){
        this.$api.visit.getidmeetorder(id).then((res) => {
          if(res.data.code === 200){
            this.meetinfo = res.data.data
          }
        })
      },
    },
    created() {
      this.loadItems();
    },
    computed: {
      ispaybtn(){
        let updata = []
        if(this.meetinfo.medicalrecord.consulting.length > 0){
          let newArrayconsulting = this.meetinfo.medicalrecord.consulting.filter(item => item.ischarge === false).map(item => {  
            return {  
              _id: item._id,
              fullName: item.fullName,
              updatatype:1,
              type:item.type,
              Totalsellingprice: item.Totalsellingprice,
              defaultcount: item.defaultcount,
              istruepay: this.convertStringToNumber(item.Totalsellingprice),
              selectedPackageUnit:item.selectedPackageUnit, 
              confirm:false
            };  
          });
          updata.push(...newArrayconsulting)
        }
        if(this.meetinfo.medicalrecord.westrecipe.length > 0){
          let newArraywestrecipe = this.meetinfo.medicalrecord.westrecipe.filter(item => item.ischarge === false).map(item => {  
            return {  
              _id: item._id,
              fullName: item.fullName,
              updatatype:2,
              type:item.type,
              Totalsellingprice: item.Totalsellingprice,
              defaultcount: item.defaultcount,  
              istruepay: this.convertStringToNumber(item.Totalsellingprice),
              selectedPackageUnit:item.selectedPackageUnit, 
              confirm:false
            };  
          });
          updata.push(...newArraywestrecipe)
        }
        if(this.meetinfo.medicalrecord.zhcnrecipe.length > 0){
          let newArrayzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.filter(item => item.ischarge === false).map((item, index) => {  
            return {  
              _id: item._id,
              fullName: `中药处方${index + 1}`,
              updatatype:3,
              Totalsellingprice: item.totalPrice,
              defaultcount: item.dosecount,  
              istruepay: this.convertStringToNumber(item.totalPrice),
              selectedPackageUnit: "剂", 
              processingpreice: item.processingpreice,
              productInfos: item.productInfos,
              confirm:false
            };  
          });
          updata.push(...newArrayzhcnrecipe)
        }
        if(updata.length > 0){
          return true
        }else{
          return false
        }
      },
    },
    mounted() {
    },
    beforeDestroy() {
    },
  };
  </script>
  <style>
  .orderrecord {
    padding:10px
  }
  .orderrecord .el-timeline{
    padding:0
  }
  .itemtooltip:hover {
    cursor: pointer;
  }
  .colorred{
    color:red;
  }
  .datepicker .el-input__prefix {
    left: 0px;
  }
  .searchpatient .el-input__prefix {
    left: 0px;
  }
  .paytotal .el-input-number__increase{
    top: 1px!important;
  }
  .box-card-paytype .el-card__body{
    padding: 10px 5px;
  }
  .paybtnbox div{
    margin-right: 10px;
  }
  .paybtnbox div:nth-child(4) {
    margin-right: 0!important;
  }
  .paybtnactive{
    background: #269CE9!important;
    position: relative;
    color:#ffffff!important;
    border:none!important;
    text-shadow:none!important;
  }
  .paybtn::-moz-focus-inner{
    border: 0;
    padding: 0;
  }
  .paybtn{
    display: inline-block;
    zoom: 1;
    padding: 6px 20px;
    margin: 0;
    cursor: pointer;
    overflow: visible;
    font: bold 13px arial, helvetica, sans-serif;
    text-decoration: none;
    white-space: nowrap;
    color: #555;
    background-color: #ddd;
    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -ms-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    background-clip: padding-box; /* Fix bleeding */
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-shadow: 0 1px 0 rgba(255,255,255, .9);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .paycardbox .el-input-number__decrease,.el-input-number__increase{
    top: 3px!important;
  }
  .el-table .warning-row {
    background: oldlace;
  }
  .paystatementflex {
    display:flex;
    flex-direction:row;
  }
  .justify-space-between {
    justify-content:space-between;
  }
  .dialogpaystatement .el-dialog{
    background-color:#e7eaf1;
  }
  .dialogpaystatement .el-dialog__header {
    padding: 0px 20px 10px;
  }
  .dialogpaystatement .el-card__header{
    padding: 10px 20px;
    border-bottom: 1px solid #EBEEF5;
  }
  .minheight400{
    min-height:400px;
  }
  .nested-zycfdose div {
    margin-right:5px;
  }
  /* .zycf-form-item-content .input-append-unit{
    right:10px!important;
  } */
  .w280{
    width: 280px;
  }
  .chaochuyc{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .aligncenter{
    align-items: center;
  }
  .lineheight50{
    line-height:50px;
  }
  .Zynameitemindex{
    display:none;
  }
  .searchmedicinedatatotal{
    text-align: center;
    padding: 10px 10px;
    border-bottom: 1px solid #dadbe0;
  }
  .activemedicineTemplate:hover {  
    cursor: pointer;
    background-color: #bebebe;
  }
  .activeitem {  
    color: white;
    background-color: #5189dd!important; 
  }  
  .el-popover {
    padding: 10 !important;
  }
  .el-dialog__wrapper .eldialog{
    margin-top: 5vh;
  }
  .eldialog .el-dialog__body {
    padding: 0!important;
  }
  .eldialog .el-dialog__header {
    padding: 0!important;
  }
  .eldialogbox{
    display:flex;
    flex-direction:row;
    height:700px;
    flex-grow:1
  }
  .eldialogboxleft{
    width:35%;
    background-color:#f5f7fb;
    display: flex;
    flex-direction: column;
    height:100%;
    border-right:1px solid #dadbe0;
  }
  .eldialogboxright{
    width:65%;
    display: flex;
    flex-direction: column;
  }
  .left-header{
    padding:10px;
    border-bottom: 1px solid #dadbe0;
  }
  .left-header .el-input__inner{
    height:35px
  }
  .left-tabs{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .left-tabs .el-tabs__nav{
    margin-left: 10px;
    margin-right: 10px;
  }
  .left-tabs-centent{
    display:flex;
    flex-direction:row;
    flex-grow:1;
    height:600px;
  }
  .left-tabs-box{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .left-tabs-box .el-tabs__header{
    margin:0;
  }
  .left-tabs-box .el-tabs__content{
    display: flex;
    flex-grow: 1;
    height:100%;
  }
  .left-tabs-box .el-tabs__content .el-tab-pane{
    display: flex;
    flex-grow: 1;
  }
  .tabs-centent-left{
    width:50%;
    border-right:1px solid #dadbe0;
    display: flex;
    flex-direction: column;
  }
  .boxli{
    flex-direction: row;
    display: flex;
    padding: 5px 5px;
    width: 100%;
  }
  .boxliicon{
    color:#72acf3;
    font-size:20px
  }
  .tabs-centent-right{
    width:50%;
    display: flex;
    flex-direction: column;
  }
  .right-header{
    padding:10px;
    text-align:center;
    border-bottom: 1px solid #dadbe0;
  }
  .righttitle{
    height:35px;
    font-size:24px;
    color:#000000
  }
  .eldialog .el-dialog__headerbtn{
    top: 10px!important;
  }
  .eldialog .el-dialog__headerbtn .el-dialog__close{
    font-size: 32px!important;
  }
  .right-useinfo{
    border: 1px solid #dadbe0;
    margin: 20px;
    background-color: #f5f7fb;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
  }
  .useinfotop{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #dadbe0;
  }
  .useinfoclass{
    width: 100px;
    padding: 10px;
    border-right: 1px solid #dadbe0;
  }
  .useinfoclasscentent{
    padding: 10px;
    border-right: 1px solid #dadbe0;
  }
  .lastuseinfoclasscentent{
    padding: 10px;
  }
  .oneuseinfoclasscentent{
    width: 100%;
    padding: 10px;
  }
  .lastuseinfotop{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    text-align: center;
  }
  .right-creater{
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .right-useinfofoot{
    border: 1px solid #dadbe0;
    margin: 0px 20px;
    background-color: #f5f7fb;
    border-radius: 2px;
    flex-grow:1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .useinfofoottitle{
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #dadbe0;
    display: flex;
    flex-direction: column;
  }
  .zycfmedicineCadn {
    display: flex;
    flex-wrap: wrap;
  }
  
  .zycfmedicineCadn > div {
    flex-basis: 25%;
    height:60px;
    border-right: 1px solid #dadbe0;
    border-bottom: 1px solid #dadbe0;
  }
  
  .zycfmedicineCadn > div:nth-child(4n) {
    border-right: none;
  }
  .zycftop{
    display:flex;
    flex-direction:column;
    border: 1px solid #dadbe0;
    margin:20px;
    background-color: #f5f7fb;
  }
  .zycffoot{
    display:flex;
    flex-direction:column;
  }
  .lasefootitem{
    width: 100%;
    border-top: 1px solid #dadbe0;
    margin-top: -1px;
    padding: 10px;
    display:flex;
    flex-direction:row;
  }
  .totaldescription{
    display:flex;
    flex-direction:row;
  }
  .totaldescription > div{
    margin-right:10px;
  }
  .zycfmedicineCadnitem{
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
  .eldialogboxright .dialog-footer{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .historybox .el-tabs__content{
    height:700px;
    padding:0!important;
    overflow: auto;
  }
  .historybox .el-collapse-item__header.is-active {
    border-bottom-color: #dadbe0;
  }
  .historybox .el-collapse-item__header{
    height:44px;
  }
  .historybox .collapsetitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    padding: 0 10px;
  }
  .flex_1{
    flex:1;
  }
  .collapsehistorydetail{
    position: relative;
    border-bottom: 1px dashed #e6eaee;
  }
  .historybox .el-collapse-item__content{
    padding-bottom:0!important;
    background-color: #fbfbfb;
  }
  .jiachu{
    color: #000;
    font-weight: 700;
  }
  .binglibtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
  .historyboxrow {
    display:flex;
    flex-direction:row;
  }
  .historyboxcolumn {
    display:flex;
    flex-direction:column;
    color: #7a8794;
  }
  .flexgrow_1{
    flex-grow:1;
  }
  .flex_wrap{
    flex-wrap: wrap;
  }
  .padbottom10{
    padding-bottom:10px;
  }
  .searchpatient .el-input__inner{
    background-color: #FFF;
    background-image: none;
    border-radius: 0!important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #DCDFE6;
  }
  .el-input__prefix {
    left: 0px;
  }
  .searchpatienttotal{
    height: 32px;
    border-bottom: 1px solid #DCDFE6;
    color: gray;
    text-align: center;
    font-size: 12px;
    line-height: 32px;
  }
  .searchpatientitem{
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mr20{
    margin-right: 20px;
  }
  .searchpatientitem:hover{
    background-color: #DCDFE6;
  }
  </style>
  <style lang="scss" scoped>
  .mrl10{
    margin-left: 10px;
    margin-right: 10px;
  }
  .ziti table tr th{
    font-family: cursive !important;
    font-weight: 700!important;
    color: #000000!important;
  }
  .historybtn{
    position: absolute;
    right: 30px;
  }
  .no-multiple {
    // 隐藏多选
    ::v-deep .el-table__header-wrapper .el-checkbox {
      display: none;
    }
  }
  thead .el-table-column--selection .cell .el-checkbox{
      display: none;
  }
  .el-message-box__btns button:focus {
    outline: none;
  }
  .no-border {
    border: none;
    color: black;
  }
  .el-tag.el-tag {
    margin: 3px;
  }
  .uf-text {
    line-height: 20px;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .uf-f1 {
    flex: 1;
  }
  .uf {
    display: flex;
    border-bottom: 1px solid #eaeaea;
    flex-direction: row;
    align-items: center;
  }
  .recipetitle {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
  }
  .recipebtnbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
  }
  button:focus {
    outline: none;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100px;
  }
  .home {
    overflow-y: auto;
    height: calc(100vh - 36px);
    background-color: #f6f9f9;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .sketch_content {
    // 必须有高度 overflow 为自动
    overflow: auto;
  
    // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
    &::-webkit-scrollbar {
      width: 3px;
    }
  
    // 滚动条里面默认的小方块,自定义样式
    &::-webkit-scrollbar-thumb {
      background: #8798af;
      border-radius: 2px;
    }
  
    // 滚动条里面的轨道
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .meetlist {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
  }
  .meetlist:hover{
    background-color: #DCDFE6;
  }
  .meettitle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ml10 {
    margin-left: 10px;
  }
  .chooceactive {
    background-color: #409eff!important;
    color: #ffffff;
  }
  .input-container {
    position: relative;
  }
  .w_100{
    width: 100px;
  }
  .w_60{
    width: 60px;
  }
  .w_120{
    width: 120px;
  }
  .w_80{
    width: 80px;
  }
  .inputcontent {
    padding: 0 !important;
  }
  
  .shortcuttitle h5 {
    font-size: 14px;
    font-weight: 700;
    margin-top: 12px;
    padding-left: 8px;
  }
  .shortcut{
    border-radius: 4px;
    color: #7a8794;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    margin-bottom: 5px;
    padding: 5px 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .shortcut:hover {
    background-color: #c6e2ff;
    color: #0090ff;
  }
  .el-textarea__inner {
    line-height: inherit;
    vertical-align: middle;
    display: flex;
    padding: 4px !important;
    background-color: #FFF;
    background-image: none;
    border-radius: 0 !important;
    border: none !important;
  }
  .el-textarea.is-disabled .el-textarea__inner {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    cursor: not-allowed;
  }
  .el-textarea {
    position: relative;
    vertical-align: middle;
    font-size: 14px;
  }
  .minheight42 {
    min-height: 42px;
  }
  .h3title{
    height: 32px;
    line-height: 32px;
    font-weight: 700;
    font-size: 14px;
    color: #000;
  }
  .userinfobox {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }
  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
  .zhengliaoxiangmu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .zlxmhj {
    border-right: 1px solid #dcdfe6;
  }
  .zhengliaoxiangmu ::v-deep .el-input__inner{
    border: none;
    border-radius: 0 !important;
  }
  .input-row ::v-deep .el-input__inner {
    border: none!important;
    outline: none!important;
    border-radius: 0 !important;
  }
  .mr10{
    margin-right: 10px;
  } 
  .el-input__inner{
    border-radius: 0 !important;
  }
  .el-select ::v-deep .el-input__inner{
    width: 80px;
  }
  .el-table ::v-deep .el-table__cell {
    padding: 5px 0;
  }
  .el-input-group__prepend{
    background-color: #F5F7FA;
    border: 1px solid #dcdfe6;
    border-radius: 0 !important;
    padding: 0 20px;
  }
  .binglititle{
    color: #626d77;
    width: 90px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-right: 1px solid #e6eaee;
    box-sizing: border-box; 
  }
  .binglititle > * {
    flex-grow: 1;
  }
  .binglibox{
    background-color: #fffdec;
    border-top: 1px solid #e0e2eb;
  }
  .bingliitem{
    display: flex;
    flex-direction: row;
  }
  .bingliitem ::v-deep input {
    border: none;
    outline: none;
    border-radius: 0 !important;
  }
  .bingliitem ::v-deep textarea {
    border: none;
    outline: none;
    border-radius: 0 !important;
  }
  .bingliitem + .bingliitem {
    border-top: 1px solid #e0e2eb; /* 替换成你想要的边框样式 */
  }
  .my-div ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 3px; /* 设置滚动条宽度 */
  }
  
  .my-div ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background: #8798af;
    border-radius: 2px;
  }
  
  .my-div ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* 设置轨道背景颜色 */
  }
  .borderbottom{
    border-bottom:1px solid #e0e2eb;
  }
  .userinfoboxwrapper ul {
    margin: 0;
    padding: 0;
  }
  .goods-item-list li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px dashed #e6eaee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    padding: 0;
    position: relative;
  }
  .goods-item-list .index {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    align-items: center;
    border-right: 1px dashed #e6eaee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    width: 30px;
  }
  .goods-item-list .name {
    -webkit-box-flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    padding-right: 4px;
    width: 0;
  }
  .goods-item-list .name .name-describe {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-left: 8px;
    width: 100%;
  }
  .goods-item-list .name .ellipsis {
    cursor: pointer;
    font-weight: 500;
  }
  .ellipsis {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .medical-fee-grade-td-wrapper .pay-type-text {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
  }
  .goods-item-list .spec {
    color: #7a8794;
    font-size: 12px;
    margin-left: 4px;
    min-width: 20px;
  }
  .goods-item-list .name .name-describe>div.tag-wrap {
    min-width: 50px;
  }
  .goods-item-list .goods-type {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    align-items: center;
    border-left: 1px dashed #e6eaee;
    color: #7a8794;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0 6px;
  }
  .goods-item-list .medical-fee-grade-td-wrapper {
    width: 40px;
  }
  .medical-fee-grade-td-wrapper {
    border: none;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .goods-item-list .dosage, .goods-item-list .unit-count {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-left: 1px dashed #e6eaee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 98px;
  }
  .bwhl-form-item {
    margin: 0;
  }
  .bwhl-form-item {
    display: inline-block;
    font-size: 0;
  }
  .bwhl-form-item .bwhl-form-item-content {
      font-size: 0;
      line-height: 1;
      position: relative;
  }
  .bwhl-input-wrapper {
      display: inline-block;
      font-size: 14px;
      outline: none;
      position: relative;
  }
  .goods-item-list .days {
      border-left: 1px dashed #e6eaee;
      height: 100%;
      width: 59px;
  }
  .bwhl-input__inner {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #fff;
      border: 1px solid #e0e2eb;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #000;
      display: inline-block;
      font-size: 14px;
      height: 32px;
      line-height: 1;
      outline: none;
      padding: 3px 8px;
      width: 100%;
  }
  .bwhl-input-wrapper.count-center .bwhl-input__inner, .input-select .bwhl-input__inner, .no-border-input .bwhl-input__inner {
      background-color: #fff;
      border-color: transparent;
      border-radius: 0;
      height: 39px;
      padding: 3px 4px;
  }
  .goods-item-list .dosage span.unit, .goods-item-list .unit-count span.unit {
      display: inline-block;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
      width: 48px;
  }
  .bwhl-form-item .bwhl-form-item-content button, .bwhl-form-item .bwhl-form-item-content p, .bwhl-form-item .bwhl-form-item-content span {
      font-size: 14px;
  }
  .goods-item-list .delete-item, .goods-item-list .input-append-unit {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    position: absolute;
    top: 0;
  }
  .goods-item-list .input-append-unit {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      right: 0;
      width: 24px;
      z-index: 2;
  }
  .count-center .bwhl-input__inner {
      text-align: center!important;
  }
  .bwhl-input-wrapper.is-hover .bwhl-input__inner:not([disabled]):not(.is-disabled) {
      border-color: #459eff!important;
      z-index: 2!important;
  }
  .goods-item-list .unit-count {
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      border-left: 1px dashed #e6eaee;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      width: 98px;
  }
  .goods-item-list .total {
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      -webkit-align-items: center;
      align-items: center;
      border-left: 1px dashed #e6eaee;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      max-width: 110px;
      min-width: 84px;
      padding-right: 10px;
      position: relative;
      width: 110px;
  }
  .goods-item-list .item-remark {
      border-left: 1px dashed #e6eaee;
      max-width: 59px;
      min-width: 59px;
      position: relative;
      width: 59px;
  }
  .goods-item-list .delete-item {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      right: -14px;
      width: 28px;
  }
  .goods-item-list .unit-count-dosage {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-left: 1px dashed #e6eaee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
  }
  select {
    outline: none;
    appearance: none; /* 清除默认的外观样式 */
    border: none; /* 去除边框 */
    background-color: transparent; /* 设置背景色为透明，以便隐藏默认的下拉箭头 */
    width: 40px;
    height: 40px;
    font-size: medium;
  }
  .goods-item-list .unit-count-dosage .ishover:hover {
    color: #ffffff;
    background-color: #459eff!important;
  }
  select::after {
    border: none; /* 去除边框 */
    content: '\25BC'; /* 使用 Unicode 字符作为箭头图标 */
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    right: 0.5rem; /* 根据需要调整箭头位置 */
    transform: translateY(-50%);
    pointer-events: none; /* 避免箭头遮挡点击事件 */
  }
  select option {
    height: 2rem; /* Set the height of each option in the dropdown */
    /* Other styles for the options */
  }
  .underline-input {
    position: relative;
  }
  .underline-input input:hover {
    border: 1px solid;
    border-color: #459eff!important;
  }
  .zxcy .table-td {
    height: 40px;
  }
  .zxcy .usage {
    border-left: 1px dashed #e6eaee;
    min-width: 77px;
    width: 9.8%;
  }
  .zxcy .table-td .bwhl-input__inner {
      border-color: transparent;
      border-radius: 0;
      height: 40px;
  }
  .selectcontainer {
    display: flex;
    flex-wrap: wrap; /* 设置自动换行 */
    width: 400px; /* 设置父div的固定宽度 */
    border: 1px solid gray;
    border-bottom: none;
  }
  
  .column {
    flex: 0 0 25%;
    border-bottom: 1px solid gray; /* 下边框，灰色线条 */
    border-right: 1px solid gray; /* 右边框，灰色线条 */
    text-align: center;
    box-sizing: border-box;
    padding: 10px; /* 设置底部间距 */
  }
  
  .selectcontainer .column:hover {
    background-color: #0090ff;
    color: #ffffff;
  }
  
  .column:nth-child(4n) {
    border-right: none; /* 每第四个div没有右边框 */
  }
  .mt10 {
    margin-top: 10px
  }
  .goods-item-list .name .shortage-tips {
      margin-left: 4px;
  }
  .el-dropdown-menu {
    max-height: 300px; /* 设置所需的固定高度 */
    overflow-y: auto; /* 当内容溢出时出现垂直滚动条 */
    overflow-x: hidden;
  }
  .zycf {
    display: flex;
    flex-wrap: wrap;
  }
  .zycf .child {
    min-height: 58px;
    border-bottom: 1px dashed #e6eaee;
    border-right: 1px dashed #e6eaee;
    width: 20%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 58px;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0 8px 0 4px;
    position: relative;
    vertical-align: top;
  }
  .zycf .child:nth-child(5n) {
    border-right: none;
  }
  .delete-icon-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1000;
  }
  .name-wrapper {
      -webkit-box-flex: 1;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      position: relative;
      width: 0;
  }
  .name-wrapper .zycf-autocomplete-wrapper {
      max-width: 168px;
  }
  .child .zycf-autocomplete-wrapper {
      background-color: #fff;
  }
  .zycf-input-small-wrapper {
      line-height: 1;
  }
  .zycf-autocomplete-wrapper {
      display: inline-block;
      font-size: 14px;
      position: relative;
      width: 100%;
  }
  .zycf-autocomplete-wrapper .zycf-input__inner {
      -webkit-font-smoothing: initial;
      font-size: 14px;
  }
  .child .zycf-input__inner {
      border-radius: 0;
      border-width: 0;
      -webkit-box-shadow: none!important;
      box-shadow: none!important;
      height: 24px;
      line-height: 1;
  }
  .child .zycf-input__inner {
      border-radius: 0;
      padding: 3px 8px;
  }
  .child .count-wrapper {
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      font-size: 15px;
      width: 51px;
  }
  .child .zycf-form-item {
      margin: 0;
  }
  .zycf-input-wrapper {
      display: inline-block;
      font-size: 14px;
      outline: none;
      position: relative;
  }
  .zycf-autocomplete-wrapper .zycf-input__inner {
      width: 100%;
  }.zycf-input__inner {
      font-family: STZhongsong;
      font-size: 15px;
      padding: 0 2px;
  }
  .child .zycf-input__inner {
      border-radius: 0;
      border-width: 0;
      -webkit-box-shadow: none!important;
      box-shadow: none!important;
      height: 24px;
      line-height: 1;
  }
  .count-wrapper .input-append-unit {
      font-family: STZhongsong;
      font-size: 15px;
      width: 16px;
      display: flex;
      align-items: center;
  }
  .child .input-append-unit {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      height: 100%;
      -webkit-justify-content: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      z-index: 2;
  }
  .delete-icon:hover{
    color: chocolate;
  }
  .name-wrapper ::v-deep .el-input__inner{
    border: none;
    border-radius: 0 !important;
    padding: 0;
    height: 100%;
    line-height: normal;
  }
  .bottombox{
    height: 41px;
    padding: 0 12px 0 9px;
  }
  .bottomboxborder{
    border-bottom: 1px dashed #e6eaee;
  }
  .flexrow{
    display: flex;
    flex-direction: row;
  }
  .flexcolumn{
    display: flex;
    flex-direction: column;
  }
  .flex-item {
    flex: 1;
    margin-right: 10px;
  }
  .flex-item—1{
    align-items: center;
    display: flex;
  }
  .align-right {
    margin-right: 0;
    margin-left: auto;
  }
  .nested-flex {
    display: flex;
    align-items: center;
  }
  .align-right {
    margin-right: 0;
    margin-left: auto;
  }
  .zycfdose {
    border-bottom: 1px solid #d1d1d1;
  }
  
  .input-element {
    width: 40px;
    border: none;
    outline: none;
    text-align: center;
    line-height: 1;
  }
  .zycfusage ::v-deep .el-popover {
    padding: 0 !important;
  }
  .zycfusage-input__inner{
    width: 72px;
    border: none;
    text-align: center;
    margin: 0 5px;
    outline: none;
  }
  .jend{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .transition-element {
    transition: opacity 0.5s; /* 添加过渡效果 */
  }
  </style>
  